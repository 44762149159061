import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  useLoginMutation,
  useRequestOtpMutation,
  useRequestPodOtpMutation,
  useVerifyOtpMutation, useVerifyPodOtpMutation,
} from '../auth-api-slice';
import TextMessage from 'src/components/text-message';
import s from './login.module.css';
import { EyeIcon, EyeSlashIcon, LoadingIcon } from 'src/assets/icons';
import { setCredentials } from '../auth-slice';
import { useAppDispatch } from '../../../redux/hooks';
import { ToastContainer } from 'react-toastify';

interface formData {
  username?: string;
  password?: string;
  user_identifier?: string;
  mobile_number?: string;
  otp_code?: string;
}

function LoginPage() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [login, { error, isLoading }] = useLoginMutation();
  const [requestOtp, { error: requestOtpError, isLoading: requestOtpIsLoading }] = useRequestOtpMutation();
  const [verifyOtp, { error: verifyOtpError }] = useVerifyOtpMutation();
  const [requestPodOtp, { error: requestPodOtpError, isLoading: requestPodOtpIsLoading }] = useRequestPodOtpMutation();
  const [verifyPodOtp, { error: verifyPodOtpError }] = useVerifyPodOtpMutation();

  const [timer, setTimer] = useState(0);
  const [open, setOpen] = useState(true);
  const [type, setType] = useState(1);
  const {
    register,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm<formData>({
    mode: 'onChange',
  });

  useEffect(() => {
    if (timer >= 0) {
      const timerID = setInterval(() => {
        setTimer((prevState:any) => prevState - 1);
      }, 1000);
      return () => {
        clearInterval(timerID);
      };
    }
  }, [timer]);

  async function handleLogin(value: any) {
    let body;
    if (type === 1) {
      body = {
        username: value.value?.username || value.username,
        password: value.value?.password || value.password,
      };
    } else if (type === 3) {
      body = {
        user_identifier: value.value?.mobile_number || value.mobile_number,
        otp_code: value.value?.otp_code || value.otp_code,
      };
    } else {
      return false;
    }
    try {
      const res = type === 1 ? await login(body).unwrap() : await verifyOtp(body).unwrap();
      localStorage.setItem('refresh', res?.data?.refresh);
      localStorage.setItem('access', res?.data?.access);
      localStorage.setItem('has_notification', res?.data?.user?.has_notification);
      const transformLabels = res?.data?.user?.label_list?.map((item: any) => {
        return {
          value: item.label_id,
          label: item.label_name,
        };
      });
      transformLabels.unshift({value: '', label: "همه"})
      localStorage.setItem('labels', JSON.stringify(transformLabels));
      if (res.status)
        dispatch(setCredentials(res?.data));
      navigate('/dashboard');
    } catch (error) {
      console.log(error);
    }
  }

  async function requestOtpHandler() {
    let body = {
      user_identifier: watch('mobile_number'),
    };
    try {
      await requestOtp({ body, type: 'login' }).unwrap();
      setType(3);
      setTimer(60);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className={s.login}>
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl
        pauseOnFocusLoss
        pauseOnHover
        theme="colored"
      />
      <form className={s.loginForm} onSubmit={handleSubmit(handleLogin)}>
        <img src="/images/pasargad.webp" alt="logo" width={79} height={65} className="mx-auto my-4" />
        {type === 1 ? <>
          <div className="parag-lg">
            <input
              {...register('username', {
                required: {
                  value: true,
                  message: 'نام کاربری الزامی می باشد',
                }
              })}
              name="username"
              type="text"
              className={`${s.formInput} placeholder:parag-lg`}
              title="نام کاربری"
              placeholder="لطفا نام کاربری خود را وارد نمایید"
            />
            {errors?.username?.message && <TextMessage msg={errors?.username?.message} type="error" />}
          </div>
          <div className="parag-lg relative">
            <input
              {...register('password', {
                required: {
                  value: true,
                  message: 'رمز عبور الزامی می باشد',
                },
              })}
              name="password"
              type={open ? 'password' : 'text'}
              className={`${s.formInput} placeholder:parag-lg`}
              title="رمز عبور"
              placeholder="رمز عبور خود را وارد نمایید"
            />
            <div onClick={() => setOpen(!open)} className="absolute left-4 top-3.5">
              {open ? <EyeIcon /> : <EyeSlashIcon />}
            </div>
            {errors?.password?.message && <TextMessage msg={errors?.password?.message} type="error" />}
            {
              // @ts-ignore
              error && <TextMessage msg={error?.data?.message} type="error" />
            }
          </div>
        </> : ''}
        {type === 2 ?
          <div className="parag-lg relative">
            <input
              {...register('mobile_number', {
                required: {
                  value: true,
                  message: 'ایمیل / موبایل الزامی می باشد',
                },
              })}
              name="mobile_number"
              type={'text'}
              className={`${s.formInput} placeholder:parag-lg`}
              title="ایمیل / موبایل"
              placeholder="موبایل/ ایمیل خود را وارد نمایید"
            />
            {errors?.mobile_number?.message && <TextMessage msg={errors?.mobile_number?.message} type="error" />}
            {
              // @ts-ignore
              requestOtpError && <TextMessage msg={requestOtpError?.data?.message} type="error" />
            }
          </div>
          : ''}
        {type === 3 ?
          <div className="parag-lg relative">
            <input
              {...register('otp_code', {
                required: {
                  value: true,
                  message: 'کد امنیتی الزامی می باشد',
                },
              })}
              name="otp_code"
              type={'password'}
              className={`${s.formInput} placeholder:parag-lg`}
              title="کد امنیتی"
              placeholder="لطفا کد یکبار مصرف را وارد نمایید"
            />
            {errors?.mobile_number?.message && <TextMessage msg={errors?.mobile_number?.message} type="error" />}
            {timer > 0 ? <p className="flex justify-end ml-2 mt-2 text-[#6C82C3]">0{Math.floor(timer / 60)}:{(timer % 60) > 9?`${timer % 60}`:`0${timer % 60}`}</p> :
              <button onClick={requestOtpHandler} className="w-full flex justify-end ml-2 mt-2 text-[#6C82C3]">ارسال مجدد کد یکبار مصرف</button>}
            {
              // @ts-ignore
              verifyOtpError && <TextMessage msg={verifyOtpError?.data?.message} type="error" />
            }
          </div>
          : ''}
        {type === 4 ?
          <div className="parag-lg relative">
            <input
              {...register('user_identifier', {
                required: {
                  value: true,
                  message: 'شماره موبایل الزامی می باشد',
                },
              })}
              name="user_identifier"
              type={'number'}
              className={`${s.formInput} placeholder:parag-lg`}
              title="شماره موبایل یا ایمیل"
              placeholder="شماره موبایل یا ایمیل ثبت شده در پاد"
            />
            {errors?.user_identifier?.message && <TextMessage msg={errors?.user_identifier?.message} type="error" />}
            {
              // @ts-ignore
              requestOtpError && <TextMessage msg={requestOtpError?.data?.message} type="error" />
            }
          </div>
          : ''}
        {type === 5 ?
          <div className="parag-lg relative">
            <input
              {...register('otp_code', {
                required: {
                  value: true,
                  message: 'کد امنیتی الزامی می باشد',
                },
              })}
              name="otp_code"
              type={'password'}
              className={`${s.formInput} placeholder:parag-lg`}
              title="کد امنیتی"
              placeholder="لطفا کد یکبار مصرف را وارد نمایید"
            />
            {errors?.otp_code?.message && <TextMessage msg={errors?.otp_code?.message} type="error" />}
            {timer > 0 ? <p className="flex justify-end ml-2 mt-2 text-[#6C82C3]">0{Math.floor(timer / 60)}:{(timer % 60) > 9?`${timer % 60}`:`0${timer % 60}`}</p> :
              <button onClick={requestOtpHandler} className="w-full flex justify-end ml-2 mt-2 text-[#6C82C3]">ارسال مجدد کد یکبار مصرف</button>}
            {
              // @ts-ignore
              verifyOtpError && <TextMessage msg={verifyOtpError?.data?.message} type="error" />
            }
          </div>
          : ''}
        {type === 2 ? <button type="button" onClick={requestOtpHandler} className={s.loginButton}>
          {requestOtpIsLoading ? <LoadingIcon fill="white" /> : 'ارسال کد'}
        </button> : <button type="submit" className={s.loginButton}>
          {isLoading ? <LoadingIcon fill="white" /> : 'ورود'}
        </button>}
        <div className={'flex justify-between'}>
          {(type === 1 || type === 2 || type === 3) ? <Link to="#" className={s.loginOTP} onClick={() => setType(type === 1 ? 2 : 1)}>
            ورود با {type === 1 ? 'موبایل/ایمیل' : 'نام کاربری'}
          </Link>:''}
          {/* <Link to="#" className={s.loginOTP} onClick={() => setType(type === 4 || type === 5 ? 1 : 4)}>
            ورود با {type === 4 || type === 5 ? 'سنباد' : 'پاد'}
          </Link> */}
        </div>
      </form>
    </div>
  );
}

export default LoginPage;
